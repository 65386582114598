<template>
    <div class="flex_layout inner">
        <div class="flex_top">
            <div class="query flex">
                <a-space>
                    <div class="query_box">
                        <a-button class="primary btn" @click="update('add', 0)">Add</a-button>
                    </div>
                </a-space>
            </div>
        </div>
        <div class="flex_body">
            <a-spin :spinning="spinning">
               <a-table :dataSource="dataSource" :columns="columns" :pagination="false">
                   <template #action="{ record }">
                       <a-space>
                           <a-button class="primary btn" @click="update('edit', record.region_id, record.region_name)" size="small" type="primary">Edit</a-button>
                           <a-popconfirm
                                title="Are you sure delete this?"
                                ok-text="Yes"
                                cancel-text="No"
                                @confirm="() => remove(record.region_id)"
                            >
                               <a-button danger size="small">Delete</a-button>
                           </a-popconfirm>
                       </a-space>
                   </template>
               </a-table>
            </a-spin>
        </div>
        <a-drawer
            title="Region"
            placement="bottom"
            height="100%"
            :visible="visible"
            @close="onClose"
            :drawerStyle="{background: '#f9f9f9'}"
            >
               <div class="container">
                   <a-spin :spinning="spinning">
                    <div class="form">
                        <div class="form_title">Region</div>
                        <a-form :model="formData" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-form-item label="Region name">
                                <a-input v-model:value="formData.region_name" placeholder="Region name" />
                            </a-form-item>
                            <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
                                <a-button type="primary" @click="updateRegionHandler">Submit</a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                   </a-spin>
               </div>
        </a-drawer>
    </div>
</template>
<script>
const columns = [
    {
        title: 'Region name',
        dataIndex: 'region_name',
        key: 'region_name',
    },
    {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' },
    },
]
import { message } from 'ant-design-vue'
import { getRegionList, updateRegion, removeRegion } from '@/apis/region'
export default {
    name: 'Product',
    data(){
        return {
            dataSource: [],
            columns,
            visible: false,
            formData: {
                region_name: '',
                region_id: 0,
                action_type: 'add'
            },
            labelCol: { span: 6 },
            wrapperCol: { span: 12 },
            spinning: false,
        }
    },
    methods: {
        update(actionType, region_id, region_name){
            this.formData.action_type = actionType
            if(actionType == 'add'){
                this.formData.region_name = ''
            }
            if(actionType == 'edit'){
                this.formData.region_name = region_name
            }
            this.formData.region_id = region_id
            this.visible = true
        },
        async updateRegionHandler(){
            if(this.formData.region_name == '') return message.error('Please input class name')
            this.spinning = true
            const res = await updateRegion(this.formData)
            this.spinning = false
            if(res){
                this.visible = false
                this.getRegionListHandler()
            } 
        },
        async remove(region_id){
            this.spinning = true
            const res = await removeRegion({region_id})
            this.spinning = false
            if(res){
                this.getRegionListHandler()
            } 
        },
        onClose(){
            this.visible = false
        },
        async getRegionListHandler(){
            this.spinning = true
            const res = await getRegionList()
            this.spinning = false
            if(res){
                this.dataSource = res.list  
            }
        }
    },
    created(){
        this.getRegionListHandler()
    }
}
</script>
<style lang="stylus" scoped>
</style>